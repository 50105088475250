import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from 'react-hot-toast'
import { pageRoutes } from 'Utils/Routes'

const Login = lazy(() => import('Components/Login'))
const MapComponent = lazy(() => import('Components/Map/MapComponent'))
const GlobalLayout = lazy(() => import('Components/GlobalLayout'))
const Profile = lazy(() => import('Pages/Profile'))
const Discover = lazy(() => import('Pages/Discover'))
const SomethingWentWrong = lazy(() => import('Pages/SomethingWentWrong'))

function App() {
  // react-query client
  const reactQueryClient = new QueryClient()

  return (
    <Suspense fallback={<div />}>
      <QueryClientProvider client={reactQueryClient}>
        <GlobalLayout>
          <BrowserRouter>
            <Routes>
              <Route path={pageRoutes.HOMEPAGE} element={<Discover />} />
              <Route path={pageRoutes.LOGIN} element={<Login />} />
              <Route path={pageRoutes.PROFILE} element={<Profile />} />
              <Route path={pageRoutes.EVENTPAGE} element={<MapComponent />} />
              <Route path={pageRoutes.NOTFOUND} element={<SomethingWentWrong />} />
            </Routes>
          </BrowserRouter>
        </GlobalLayout>
        <ReactQueryDevtools />
      </QueryClientProvider>
      <Toaster position='top-center' />
    </Suspense>
  )
}

export default App
