export const pageRoutes = {
  HOMEPAGE: '/',
  LOGIN: '/login',
  EXPLORE: '/explore',
  PROFILE: '/profile',
  EVENTPAGE: '/m/:brandSlug/:eventSlug',
  NOTFOUND: '/*',
}

export const apiRoutes = {
  NFT: 'nft/',
  USER: 'user/',
  LOGOUT: 'user/logout/',
  INVENTORY: 'user/inventory/',
  CLAIM: 'claim/',
  CLAIM_RADIUS: 'nft/claim-radius',
  BRAND: 'brand',
  EVENT: 'event/',
}
